(function ($, widgets) {
  var SingleColorPicker = widgets.Widget.extend(
    {
      constructor: function SingleColorPickerConstructor(object) {
        SingleColorPicker.__super__.constructor.call(this, object);

        /**
         * The measured left and right margin of the response items, in pixels.
         * @private
         */
        this._responseMargin = 0;
      },

      render: function render() {
        SingleColorPicker.__super__.render.call(this);
        var self = this;
        var responseElements = (this._responses =
          this._createResponseElements());
        var responseListContainer = this.$el.find('.question-response-list');
        var config = {
          columns: this._object.columns,
          minColumns: this._object.min_columns,
          thresholds: [widgets.MEDIA_SKINNY_MAX_WIDTH],
          wrap: this._object.wrap,
          horizontal: this._object.horizontal,
          autoadvance: this._object.autoadvance,
          onLayout: $.proxy(this, '_onLayout'),
        };
        this.layout = new Gryphon.widgets.layout.DynamicRowLayout(
          responseListContainer,
          responseElements,
          config
        );
        this.layout.render();
        if (config.autoadvance) {
          this.$el.find('.response-button').on('click', click_next);
        }
        $(window).on(
          'resize',
          _.debounce(function () {
            self._resizeButtonsForLayout(self.layout);
          }, 250)
        );

        // Accessible element for previous answers (multiple)
        if (this._object.answer && this._object.answer.length > 0) {
          this._object.answer.forEach((value, idx) => {
            const response = this._object.response_options.find(
              (element) => element.code === value
            );
            if (response) {
              $(`#${this._object.input_id}_note`).append(
                idx + 1 >= this._object.answer.length &&
                  this._object.answer.length > 1
                  ? `and ${response.text}`
                  : this._object.answer.length > 1
                  ? `${response.text}, `
                  : response.text
              );
            }
          });
        }
        var exclusiveInput = this._object.response_options.find(function (el) {
          return el.options && el.options.xor === true;
        });
        if (exclusiveInput) {
          var exclusiveInputSelector;

          if (exclusiveInput.input_name) {
            exclusiveInputSelector = `input[name="${exclusiveInput.input_name}"]`;
          } else {
            exclusiveInputSelector = `input#r-${this._object.input_id}-${exclusiveInput.code}`;
          }

          var exclusiveInputEl = this.$el.find(exclusiveInputSelector);

          var allNormalInputsText = this.$el
            .find('input[type="text"],textarea')
            .not(exclusiveInputSelector);

          var allNormalInputsSelect = this.$el
            .find('input:not([type="text"])')
            .not(exclusiveInputSelector);

          var hideResponseInputs = function () {
            allNormalInputsText
              .addClass('hidden-response')
              .attr('readonly', true);
            allNormalInputsSelect.attr('readonly', true);
          };

          var showResponseInputs = function () {
            allNormalInputsText
              .removeClass('hidden-response')
              .attr('readonly', false);
            allNormalInputsSelect.attr('readonly', false);
          };

          if (exclusiveInputEl.is(':checked')) {
            hideResponseInputs();
          }

          exclusiveInputEl.on('click', function (e) {
            if (e.target.checked) {
              hideResponseInputs();
            } else {
              showResponseInputs();
            }
          });

          allNormalInputsSelect.on('click', function () {
            showResponseInputs();
          });

          allNormalInputsText.on('click', function () {
            showResponseInputs();
          });
        }
      },

      _createResponseElements: function () {
        var parentData = {
          input_type: this._object.input_type,
          // input_name may be overridden by response by design
          input_name: this._object.input_id,
          group: this._object.input_id,
          answer: this._object.answer,
          autoadvance: this._object.autoadvance,
        };
        var responses = $.map(
          this._object.response_options,
          function (response) {
            var data = $.extend({}, parentData, response);
            return $(Gryphon.templates['response-button'](data))[0];
          }
        );
        var $responses = $(responses).responsebutton({
          color: this._object['cp-color'],
          input_name: this._object.input_id,
        });
        this._equaliseButtonHeightsOnImageLoad($responses);
        this._setResponseMargin($responses);
        return $responses;
      },

      _setResponseMargin: function _setResponseMargin($responses) {
        var response = $responses.eq(0);
        this._responseMargin =
          parseInt(response.css('margin-left'), 10) +
            parseInt(response.css('margin-right'), 10) || 0;
      },

      _onLayout: function _onLayout(layout) {
        this._resizeButtonsForLayout(layout);
      },

      destroy: function destroy() {
        SingleColorPicker.__super__.destroy.call(this);
        this.layout.destroy();
      },

      _equaliseButtonHeightsOnImageLoad:
        function _equaliseButtonHeightsOnImageLoad(buttons) {
          var images = buttons.find('img');
          var equaliseHeights = $.throttle(
            Gryphon.widgets.RELAYOUT_PERIOD,
            $.proxy(function (event) {
              this._resizeButtonsForLayout(this.layout);
            }, this)
          );
          images.one('load', equaliseHeights);
        },

      _resetButtonSizes: function _resetButtonSizes() {
        this._responses.css('display', '').width('auto').height('auto');
      },

      _equaliseButtonSizes: function _equaliseButtonSizes(dimension) {
        this._responses.equalisr(dimension);
      },

      /**
       * Resize buttons based on the provided layout.
       *
       * @param {object} layout The layout instance.
       */
      _resizeButtonsForLayout: function (layout) {
        layout.container.css('width', '');
        var widthOption = this._object.width;
        var thresholdWidth = layout._object.thresholds[0];
        var isLargeDevice = layout.status.windowWidth > thresholdWidth;
        if (widthOption && isLargeDevice) {
          var requestedWidth =
            (widthOption + this._responseMargin) * layout.status.columns;
          layout.container.width(requestedWidth);
          // [GRYP-7667] when setting a 'width' option, we need to overwrite the
          // behavior of using flexbox for the last column in a given row
          layout.container.addClass('user-defined-width');
        }

        this._resetButtonSizes();
        this._responses.responsebutton('hyphenate');
        // TODO: the size can be archived with css only
        if (isLargeDevice || layout.status.columns > 1) {
          if (layout.status.columns === 1 && !widthOption) {
            this._responses.css('display', 'inline-block');
            this._equaliseButtonSizes('width');
          }
          this._equaliseButtonSizes('height');
        }
      },
    },
    {
      types: ['single-colorpicker', 'language'],
      views: ['single-colorpicker'],
    }
  );

  SingleColorPicker.register();
  widgets.SingleColorPicker = SingleColorPicker;

  var MultipleColorPicker = SingleColorPicker.extend(
    {
      // Used by 'displaymax'
      p_next: function p_next(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var cur = this._object.phase_pos;
        this.clear_required_message();
        if (this.required_is_satisfied()) {
          this.displaymax_soft_prompts = 0;
          if (cur + 1 > this._object.phase_max) {
            click_next({ key: ev.key, type: ev.type });
          } else {
            this._object.phase_pos++;
            this.setPagedVisibility();
            this.update_paged_buttons();
          }
        } else {
          this.display_required_message('.question-response-list');
        }
        return false;
      },

      // Used by 'displaymax'
      p_back: function p_back(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var cur = this._object.phase_pos;
        if (cur - 1 < 0) {
          click_back({ key: ev.key, type: ev.type });
        } else {
          this._object.phase_pos--;
          this.setPagedVisibility();
          this.update_paged_buttons();
        }
        return false;
      },

      // Used by 'displaymax'
      get_phase_rows: function get_phase_rows() {
        return $('.dynamic-row[data-index]');
      },

      /**
       * Further extends the base functionality to check whether
       * we are on the last response option before determining
       * satisfaction of the required flag
       *
       */
      required_is_satisfied: function required_is_satisfied() {
        var required = this._object.required === 'HARD' ? true : false;
        var soft_required = this._object.soft_required;
        // If we're on the last page, check for satisfaction of requirements
        // otherwise return `True` to continue paginating
        if (this._object.phase_pos + 1 > this._object.phase_max) {
          if (required || (soft_required && !this.displaymax_soft_prompts)) {
            if (this.selected_rows_count() < 1) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
    {
      types: ['multiple-colorpicker'],
      views: ['multiple-colorpicker'],
    }
  );

  MultipleColorPicker.register();
  widgets.MultipleColorPicker = MultipleColorPicker;
})(jQuery, (Gryphon.widgets = Gryphon.widgets || {}));
